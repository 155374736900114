.voiceToText {
  position: absolute;
  bottom: 100%;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  font-size: 14px;

  @media (max-height: 700px) {
    font-size: 14px;
  }

  @media (min-height: 900px) {
    font-size: 18px;
  }

  @media (orientation: landscape) {
    font-size: 24px;
  }
}

.participant {
  display: flex;
}

.participant-name {
  width: 4rem;
  font-weight: 500;
  line-height: 1.5;
  text-shadow: 1px 1px 4px #000;
  color: #FFFFFF;
  opacity: 0.6;

  @media (min-height: 900px) {
    width: 5rem;
  }

  @media (orientation: landscape) {
    width: 10rem;
  }
}

.phrase {
  line-height: 1.5;
  text-shadow: 1px 1px 4px #000;
  color: #FFFFFF;
}
