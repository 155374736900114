.pageLogo {
  position: absolute;
  z-index: 50;
  display: flex;

  a {
    display: flex;
  }

  img {
    width: 160px;

    @media (max-width: 780px) {
      width: 140px;
    }

    @media (max-width: 540px) {
      width: 130px;
    }

    @media (max-width: 380px) {
      width: 120px;
    }

    transform: translate3d(0px, 0px, 0px);
  }

  &.hidden {
    display: none;
  }

  &.bottomleft {
    bottom: 24px;
    left: 24px;

    @media (max-width: 780px) {
      bottom: 18px;
      left: 20px;
    }

    @media (max-width: 540px) {
      bottom: 14px;
      left: 18px;
    }

    @media (max-width: 380px) {
      bottom: 10px;
      left: 16px;
    }
  }

  &.topleft {
    top: 10px;
    left: 10px;
  }
}
