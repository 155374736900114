.hang-out-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e96878;
  cursor: pointer;
  box-sizing: border-box;

  & svg {
    height: 65%;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.hang-out-button-animated {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: #e96878;
  cursor: not-allowed;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;

  .circle {
    width: 25%;
    height: 25%;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
  }

  .circle-left {
    @extend .circle;

    animation-name: spin-left;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    left: 30%;
    transform: translate(-50%, -50%);
  }

  .circle-right {
    @extend .circle;

    animation-name: spin-right;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    right: 30%;
    transform: translate(50%, -50%);
  }

  @keyframes spin-left {
    0% {}

    50% {
      left: 50%;
      width: 12.5%;
      height: 12.5%;
    }

    100% {
      left: 70%;
      width: 25%;
      height: 25%;
    }
  }

  @keyframes spin-right {
    0% {}

    50% {
      right: 50%;
      width: 50%;
      height: 50%;
    }

    100% {
      right: 70%;
      width: 25%;
      height: 25%;
    }
  }
}
