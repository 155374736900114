.errorMessage_wrapper{
    color: #828C9E;
    font-size: 14px;
    line-height: 16px;
    padding: 0.5rem;
    text-align: center;
    line-break: strict;
    .modal-controls > svg {
        width: 12px;
    }
}

.errorMessage{
    word-break: break-word;
}

.errorMessage_title {
        font-size: 18px !important;
        font-weight: 500;
        color: #000 !important;
        text-align: center;
        align-self: center;
        width: 100%;
        text-transform: capitalize;
}

.browserRecommendations{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    .browserSuggestion{
        display: flex;
        &:nth-child(n+2){
            &::before{
                content: 'or';
                width: 2rem;
                height: 100%;
                display: block;
                margin: auto 0;
                }
            }
        svg {
            display: block;
            width: 4rem;
            height: 3rem;
        }
    }
}