.pagination-chevron {
  position: fixed;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.pagination-chevron.prev {
  left: 0;
}

.pagination-chevron.next {
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

.pagination-chevron.desktop {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .pagination-chevron.mobile {
    display: none;
  }
  .pagination-chevron.desktop {
    display: block;
  }

  .pagination-chevron.prev {
    left: 20px;
  }

  .pagination-chevron.next {
    right: 20px;
  }
}
