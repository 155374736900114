.chat-footer-container-outer {
    display: block;

    &.chat-ai {
        .chat-footer-container {
            background-color: #1B1B1B;
            gap: 10px;

            .chat-footer-input {
                background-color: #272727;
                border: none;
                flex-basis: 100%;
                color: white;
                padding-top: 14px;
                padding-left: 10px;
                font-family: Roboto;
            }
        }

        .qr-chat-attachments-view {
            border-top: unset;
        }
    }

    .chat-footer-container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        background-color: #e4e6ea;
        padding: 12px;
        flex-basis: 3%;
        flex-grow: 0;
        flex-shrink: 0;

        &.ipad-screenkeyboard-fix {
            align-items: flex-start;
            .chat-controlbtn {
                padding-top: 0.2rem;
            }
            .chat-footer-input {
                &:focus{
                margin-bottom: 1rem;
            }}
        }

        .chat-footer-button-attachments {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background-color: #d5d8dc;
            cursor: pointer;
        }

        .chat-footer-button-send {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            border: none;
            background: none;
            padding: unset;
            &.disabled {
                opacity: 0.5;
            }
        }

        .chat-footer-input {
            flex-basis: 78%;
            @media only screen and (min-width: 1024px) {
                flex-basis: 95%;
            }
            border-radius: 15px;
            min-height: 30px;
            background-color: #ffffff;
            padding-top: 5px;
            padding-left: 5px;
            resize: none;
            outline: none;
            overflow: auto;
            padding-right: 2px;
            font-size: 16px;
        }

        .chat-footer-input::-webkit-scrollbar {
            display: none;
        }

    }



    .qr-chat-attachments-view {
        display: flex;
        flex-flow: row nowrap;
        overflow-x: auto;
        background-color: #ffffff;
        border-top: 1px solid #ebedf0;
    }
    .qr-chat-attachments-view::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.2);
      }
    .qr-chat-attachments-view::-webkit-scrollbar {
        height: 6px;
      }

    .qr-chat-attachment-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ebedf0;
        margin: 5px 5px;
        min-width: 120px;
        height: 50px;
        border-radius: 10px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);

        &.uploading {
            justify-content: space-between;
        }

        .qr-chat-attachments-upload-progress {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 50%;
            width: 50%;
            svg {
                position: relative;
                height: 100%;
                width: 100%;
            }
        }

        .qr-chat-attachment-item-close {
            align-self: flex-end;
            margin-right: 10%;
        }

        .qr-chat-attachment-item-close::after {
            content: 'x';
            color: #a0a2ad;
            font-weight: 300;
            font-family: Arial, sans-serif;
            cursor: pointer;
        }

        .qr-chat-attachment-item-name {
            color: #a0a2ad;
            text-overflow: ellipsis;
            overflow: hidden; 
            max-width: 80px;
            white-space: nowrap;
        }

        svg {
            position: absolute;
            transform: rotate(-90deg);
            stroke-dasharray: 251; /* (2PI * 40px) */
            stroke-dashoffset: 251;
        }
    }

}