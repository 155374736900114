.qr-chat-closable-popup-error {
    position: absolute;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: pre-line;
    word-break: break-word;
    padding-top: 0.8rem;
    flex-flow: column nowrap;
    width: 16rem;
    height: 5.5rem;
    border-radius: 10px;
    background-color: #f37676;
    z-index: 1000;

    &::after {
        content: "";
        position: absolute;
        border: 0.5rem solid transparent;
    }

    &.top {
       bottom: 90%;

        &::after {
            top: 99%;
            border-top: 0.5rem solid #f37676;
        }

    }

    &.bottom {
       top: 120%;

        &::after {
            bottom: 99%;
            border-bottom: 0.5rem solid #f37676;
        }
    }

    &.left {
        left: 0px;

        &::after {
            left: 5%;
        }
    }

    &.right {
        right: 0px;

        &::after {
            right: 5%;
        }
    }

    .qr-chat-closable-popup-error-title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    .qr-chat-closable-popup-error-text {
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-top: 0.3125rem;
    }

    img {
        position: absolute;
        top: 15%;
        right: 5%;
        cursor: pointer;
    }
}
