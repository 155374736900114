.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: modal-fade-in 300ms;

  .modal-head {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    color: #b7b9c3;
    margin-bottom: 1rem;

    .modal-controls {
      width: 100%;
      display: flex;
      .modal-close-button {
        margin-left: auto;
      }
      .modal-left-button {
        margin-right: auto;
      }
    }
    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #b7b9c3;
    }
    .action-button-left {
      height: 2rem;
      cursor: pointer;
    }
    .action-button {
      height: 1rem;
      cursor: pointer;
    }
  }

  .modal-content {
    position: relative;
    border-radius: 11px;
    background: white;
    width: 35rem;
    max-width: 100%;
    max-height: 100%;

    animation: modal-show 300ms;
    .info-message {
      display: none;
      color: #6d7184;
    }
    overflow: hidden;
    &.disabled {
      > div {
        user-select: none;
        cursor: default;
        pointer-events: none;
      }
      .info-message {
        display: block;
        position: absolute;
        bottom: 0.2rem;
        right: 2rem;
      }
    }

    .modal-body {
      position: relative;
      word-break: break-all;
      padding: 1rem 0 0;
    }
  }

  &.screen-settings-modal {
    .modal-content {
      width: 80rem;
    }
  }

  &.reset-confirmation-modal {
    .modal-content {
      .modal-body {
        word-break: normal;
        text-align: center;
      }
    }
  }

  .modal-actions {
    padding: 1.5rem 0 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .btn {
      margin: 0 0.5rem;
    }
  }
}

@keyframes modal-show {
  from {
    transform: scale(0.3)
  }
  to {
    transform: scale(1)
  }
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wc-modal-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  margin-top: 2rem;
}

.wc-server-error {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #ec6666;
  flex: 1 100%;
}

.wc-uploading-message {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #6d7184;
  flex: 1 100%;
}

.wc-template-modal-content {
  overflow: visible !important;
  .modal-head {
    > div:first-child {
      display: none;
    }
    h3 {
      color: #4e4f6b;
    }
  }

  .wc-modal-controls {
    justify-content: flex-end;
    .wc-button {
      height: 3.5rem;
      margin-left: 2rem;
    }
  }

  .wc-tab-view {
    margin: 0 -2rem;
  }
}

.wc-template-modal-preview-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.wc-template-modal-preview-toggle-button {
  position: absolute;
  left: 2rem;
  width: 2.5rem;
  height: 5rem;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border-radius: 10px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  .iconWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .wc-template-modal-preview-toggle-button-separate-line {
    width: 100%;
    height: 1px;
    background: #eeeeee;
  }
}
.wc-template-modal-preview {
  background-color: white;
  position: absolute;
  border-radius: 11px;
  margin-left: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  .wc-screen-preview {
    width: 100%;
    height: 100%;
  }
}
