.phone-ring-wrapper {
  width: 100%;
  height: 100%;
}

.phone-ring-icon-wrapper {
  padding: 10%;
}

.phone-and-waves {
  overflow: visible;
  width: 100%;
  height: 100%;
}

.inner {
  animation: fadeinout ease-in-out infinite;
  animation-fill-mode: forwards;
  animation-duration: 4s;
  animation-delay: 0.5s;
  opacity: 0;
}

.outer {
  animation: fadeinout ease-in-out infinite;
  animation-fill-mode: forwards;
  animation-duration: 4s;
  animation-delay: 1s;
  opacity: 0;
}

.phone {
  animation: shake 1.3s infinite;
  transform-origin: 50% 50%;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-15deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(15deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-15deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(15deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
