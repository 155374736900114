.noVideo {
    display: none;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    margin: auto 0;
    background-color: #2F3243;
    svg {
        height: 6rem;
        width: 6rem;
        margin: auto 0;
        padding: 2rem;
        background: linear-gradient(0deg, #2F3243, #2F3243);
        border-radius: 8rem;
    }
    img {
        height: 144px;
        width: 144px;
        padding: 0.5rem;
        background: linear-gradient(0deg, #2F3243, #2F3243);
        border-radius: 50%;
        margin: auto 0;
    }
}