.mw-website-screen {
  display: flex;
  flex-flow: column;
  .mw-screen-iframe-container {
    flex: 1;
    min-height: 0;
    background-color: white;
    -webkit-overflow-scrolling: touch;
  }
  iframe {
    border-width: 0;
    background-color: white;
    height: 100%;
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  .direct-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .link-button {
      width: 176px;
      padding: 10px 0;
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
    }

    a {
      text-decoration: none;
    }
  }
}
