.warningContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: white;
  text-align: center;

  .roundBox {
    position: relative;
    width: 5rem;
    height: 5rem;
    border: solid 10px #5364e4;
    border-radius: 50%;
    margin-bottom: 1rem;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 1.5rem;
    }
  }
}