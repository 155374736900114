.mw-navigation-screen {
  background-color: white;
  display: flex;
  flex-direction: column;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mv-navigation-screen-grid-wrapper {
    position: relative;
    display: flex;
    height: calc(100% - 5rem);
    padding: 0.5rem 0;
    box-sizing: border-box;
  }

  .mv-navigation-screen-buttons-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 60px;
    grid-gap: 10px;
    align-content: safe center;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    z-index: 1;
    box-sizing: border-box;
    margin: 0;

    @media only screen and (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 120px;
      padding: 0 calc(50% - 400px);
      box-sizing: border-box;
    }

    a, button {
      span {
        white-space: pre-wrap;
        word-break: break-word;
      }

      width: 100%;
      min-width: 0;
      height: 60px;
      min-height: 2rem;
      box-sizing: border-box;
      box-shadow: 2px 2px 3px 0 #f2f2f2;
      border-radius: 4px;
      border: 1px solid #f2f2f2;

      @media only screen and (min-width: 1024px) {
        height: 120px;
        font-size: 20px;
      }

      display: flex;
      align-items: center;
      justify-content: center;

      vertical-align: middle;
      overflow: hidden;
      text-decoration: none;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      letter-spacing: normal;
      cursor: pointer;
      white-space: pre-wrap;
      word-break: break-word;
      color: #fff;
      background-color: #000;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .mw-text-screen {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
