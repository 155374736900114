.chat-body-message-container {
    display: flex;
    flex-flow: column;
    margin: 10px 0.5rem;
    max-width: 75%;
    &.right {
      align-self: flex-end;
    }

    &.left {
      align-self: flex-start;
    }

    &.chat-ai {
      .chat-body-message-consultant {
        background-color: white;
        border-radius: 16px 16px 16px 4px !important;
      }

      .chat-body-message {
        border: solid #2a3158e0 6px;
        border-radius: 16px 16px 4px 16px;

        .chat-body-message-time {
          margin-bottom: 0.5em;
          margin-top: 0.5rem;
        }
      }
    }

    .chat-body-message-consultant-info-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      .chat-body-message-consultant-img {
        border-radius: 50%;
        width: 36px;
        height: 36px;
      }

      .chat-body-message-consultant-name {
        margin-left: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4e4f6b;
        word-break: break-word;
      }

    }

    .chat-body-message {
      border-radius: 10px;
      max-width: 500px;
      min-width: 50px;
      padding-left: 12px;
      padding-right: 8px;

      .message-info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }

      .chat-body-message-time {
        opacity: 0.5;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 0.5em;
        margin-top: 0.5rem;
      }

      .chat-body-message-text {
        margin-top: -5px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        word-break: break-word;
        white-space: pre-line;
        margin-bottom: 0.5em;
      }
    }

    .chat-body-message-me {
      @extend .chat-body-message;
      background-color: #5465e6;
      border-bottom-right-radius: 0;

      .chat-body-message-time {
        color: #ffffff;
      }

      .chat-body-message-text {
        color: #ffffff;

        a {
          text-decoration: underline;
          color: #ffffff;
        }
      }
    }
              
    .chat-body-message-consultant {
      @extend .chat-body-message;
      background-color: #e4e6ea;
      border-top-left-radius: 0;

      .chat-body-message-time {
        color: #9ba1af;
      }

      .chat-body-message-text {
        color: #4e4f6b;

        a {
          text-decoration: underline;
          color: #5465e6;
        }
      }
    }
    
    .qr-chat-message-attachments-container {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      border-top: solid 1px #e3e6ff;

      .qr-chat-message-attachment-item {
          margin: 5px;
          padding: 5px;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
          background-color: #e3e6e9;
          flex: 1 0 calc(100% / 3 - 11px);
      }

      .qr-chat-message-attachment-image {
          @extend .qr-chat-message-attachment-item;
          display: flex;
          align-items: center;
          max-width: 50%;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100%;
              max-height: 100%;
          }
          }
      }

      .qr-chat-message-attachment-pdf {
          @extend .qr-chat-message-attachment-item;
          display: flex;
          flex: 1 0 calc(100% - 10px);
          min-width: calc(100% - 10px); 
          padding: 3px;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          text-decoration: none;

          img {
              min-width: 18px !important;
              min-height: 18px !important;
              max-width: 18px !important;
              max-height: 18px !important;
              margin-right: 5px;
          }

          p {
              font-family: RobotoRegular;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.2px;
              text-align: center;
              color: #a0a2ad;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 100%;
          }

          img {
              width: 30px;
              height: 30px;
          }
      }
  }
  }
