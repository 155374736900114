.additional-letters {
  grid-column-start: 1;
  grid-column-end: 4;
  border-radius: 4rem;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media (min-width: 720px) {
    height: 4rem;
  }

  .close-letter-button {
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: inherit;

    @media (min-width: 720px) {
      width: 4rem;
    }

    .icon-with-border {
      height: 35%;
      border-right: 2px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .letters-slider {
    height: 1.5rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (min-width: 720px) {
      height: 2.5rem;
    }

    .letters {
      height: 1.5rem;
      display: grid;
      overflow: hidden;
      grid-template-rows: 1.5rem;
      grid-template-columns: 1fr 1fr 1fr;

      @media (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .letter {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        color: #fff;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          cursor: pointer;
        }
      }
    }

    .slider-button {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
    }

    .slider-button-disabled {
      opacity: .1;
      cursor: default;
    }
  }
}
