.webscreenInput {
  box-sizing: border-box;
  padding: 14px 5px;
  border: none;
  outline: 0;
  background-color: transparent;
  width: 100%;

  border-bottom: solid 2px #8c95c8;

  color: white;
  border-radius: 0;
}

.webscreenMultirowInput {
  overflow-y: auto;
  font-size: 14px;
  resize: none;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8c95c8;
  }
}

.inputWrapper {
  position: relative;
}

.countDownLabel {
  right: 0;
  font-size: 12px;
  position: absolute;
  color: #8c95c8;
  font-weight: 500;
}
