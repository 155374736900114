.mw-videotext-screen {
  display: flex;
  flex-flow: column;
  align-items: center;

  .mw-screen-videotext-container {
    display: flex;
    flex-flow: column;
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    background-color: white;
    overflow: hidden;

    iframe, video {
      border: none;
      width: 100%;
      height: 40%;
      background-color: black;
    }

    .mw-videotext-text {
      flex: 1;
      min-width: 0;
      min-height: 0;
      flex-shrink: 0;

      padding: 1rem 1rem 3rem 1rem;
      font-size: 0.8rem;
      line-height: 1.5;
      color: #4e4f6b;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .mw-videotext-screen {
    .mw-screen-videotext-container {
      iframe, video, .mw-videotext-text {
        width: 1024px;
      }

      .mw-videotext-text {
        overflow: auto !important;
        padding: 1rem 0;
      }
    }
  }
}
