.webscreenTextAreaContainer {
  width: 100%;
  height: 70px;

  border-bottom: solid 2px #8c95c8;
}

.webscreenTextArea {
  font-family: Roboto;
  box-sizing: border-box;
  padding: 0 5px;
  border: none;
  outline: 0;
  background-color: transparent;
  width: 100%;
  min-height: 100%;
  overflow: hidden;

  color: white;
  border-radius: 0;

  resize: none;
}

.webscreenTextAreaCharCounter {
  margin: .3rem 0 0;

  text-align: right;
  font-family: Roboto;
  font-size: .8rem;
  color: #646778;
}
