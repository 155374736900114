.mw-pagination-view {
  position: fixed;
  z-index: 10;
  top: 0.6rem;
  width: 100%;

  display: flex;
  justify-content: center;

  .mw-pagination-circle {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    border-radius: 50%;
    background-color: rgba(203, 198, 187, 0.4);
    transition: background-color 300ms ease;
    cursor: pointer;
  }
  .mw-pagination-circle.white {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  .mw-pagination-circle.white.selected {
    background-color: rgba(255, 255, 255, 1)
  }
  .mw-pagination-circle.selected {
    background-color: rgba(203, 198, 187, 1);
  }
}
