@import "../../theme/common";

.tapMeContentContainer {
  background-color: #000000;

  .tapMeInnerContainer {
    font-size: 18px;
    color: white;
    flex-flow: column nowrap;
    user-select: none;
    transform: translateY(-50px);
  }

  .tapMeErrorContainer {
    font-size: 18px;
    color: white;
    padding: 1.5rem 2.5rem;
    background-color: #3d3d52;
    border-radius: 4px;
  }

  .tapMeCompanyName {
    @extend %elipsis;
  }

  .tapMeHoldText {
    @extend %elipsis;

    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .tapMeButton {
    @extend %flex-center;

    width: 200px;
    height: 200px;
    background-color: rgba(84, 101, 230, 0.2);
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 0;
    transition: width 100ms ease-in, height 100ms ease-in, margin 100ms ease-in, padding 100ms ease-in;

    .innerButton {
      @extend %flex-center;

      width: 75%;
      height: 75%;
      background-color: #5465e6;
      border-radius: 50%;
      box-shadow: 0 0 15px 8px rgba(0, 0, 0, 0.1);
      transition: width 100ms ease-in, height 100ms ease-in, box-shadow 100ms ease-in;

      svg {
        width: 45%;
      }
    }
  }

  .tapMeButton.active:active {
    width: 196px;
    height: 196px;
    margin-top: 2px;
    padding-bottom: 2px;

    .innerButton {
      width: 78%;
      height: 78%;
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .tapMeButton.inactive {
    background-color: rgba(155, 155, 155, 0.2);

    .innerButton {
      background-color: rgba(155, 155, 155, 1);
    }
  }

  .qrCodeButton {
    transform: translateY(3rem);
  }
}

.chatAiButton {
  position: absolute;
  top: 10%;
  right: 0;
  cursor: pointer;
  z-index: 1;
}
