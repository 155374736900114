.wc-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #6d7184;
    font-size: 14px;

    .wc-checkbox {
        width: 16px;
        height: 16px;

        display: flex;
        align-content: center;
        justify-content: center;

        border-radius: 4px;
        border: solid 1px #d2d3da;
        background-color: #ffffff;

        cursor: pointer;
        svg {
            opacity: 0;
            transition: opacity 200ms ease, width 200ms ease, height 200ms ease;
        }
        &.checked svg {
            opacity: 1;
        }
    }
    .wc-checkbox-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        user-select: none;
        margin-left: 10px;
        cursor: pointer;
    }
}
