.surveyRateChartContainer {
  max-width: 100vw;
  text-align: center;
  background-color: #000000;

  .emotionStep {
    min-width: 300px;

    .emotionTitle {
      font-size: 18px;
      color: white;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 200ms ease, transform 200ms ease;

      &.hidden {
        transform: translateY(-20px);
        opacity: 0;
      }
    }

    .emotionContainer {
      margin-top: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: margin-top 500ms ease-in-out;

      &.active {
        margin-top: 20px;
      }

      div {
        flex-grow: 1;
        width: 60px;
        height: 60px;
        opacity: 1;
        transition: width 500ms ease-in-out, height 500ms ease-in-out, flex-grow 500ms ease-in-out, opacity 500ms ease-in-out;
      }

      div.inactiveIcon {
        flex-grow: 0;
        width: 0;
        opacity: 0;
      }

      div.activeIcon {
        flex-grow: 1;
        height: 200px;
        width: 200px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .messageStep {
    max-width: 290px;
    transform: translateY(-50px);

    .messageTitle {
      font-size: 18px;
      color: white;
      margin-bottom: 25px;
    }

    .buttonContainer {
      margin-top: 30px;

      .qrCodeButton {
        margin-bottom: 14px;
      }
    }
  }

  .thankYouStep {
    font-size: 18px;
    color: white;
  }
}
