.mw-servicenow-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mw-servicenow-submitted-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 80%;
    color: #4e4f6b;
    text-align: center;
  }

  .mw-servicenow-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 20rem;
    max-width: 80%;
    margin: 1rem auto 3.5rem;

    .mw-servicenow-message {
      margin-bottom: 2rem;
      text-align: center;
      word-break: break-word;
      color: #4e4f6b;
    }

    .mw-servicenow-error {
      margin-top: 1rem;
      font-size: 13.3333px;
      color: #e96878;
    }

    .mw-servicenow-comment {
      border: 1px solid #8c95c8;
      border-radius: 4px;
      padding: 0.5rem;
      height: calc(100px + 1rem);
      box-sizing: border-box;
      background-color: #fff;

      .webscreenTextAreaCharCounter {
        margin: 0.8rem 0 0;
      }

      textarea {
        min-height: 95px;
        color: #4e4f6b !important;
      }
    }

    .mw-servicenow-input {
      border: 1px solid #8c95c8;
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 0.5rem calc(0.5rem + 5px);
      color: #4e4f6b !important;
      background-color: #fff;
    }

    .mw-servicenow-submit {
      margin-top: 2rem;
      padding: 14px 30px;
      cursor: pointer;
    }

    .mw-servicenow-phone {
      position: relative;
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.5rem;
      border: 1px solid #8c95c8;
      border-radius: 4px;
      background-color: #fff;

      .webscreenInput {
        margin-bottom: 0;
        color: #4e4f6b !important;
        border: none;
        padding: 0.5rem 12px;
      }

      .dropdownContainer {
        z-index: 10;
        width: 6rem;
        font-size: 13.3333px;
        height: 100%;
        box-sizing: border-box;
        padding: 0.5rem 12px;
        color: #4e4f6b !important;
        border-right: 1px solid #8c95c8;
        border-bottom: none;

        .dropdownContent {
          top: -1px;
          left: -1px;
          right: -1px;
          border-radius: 4px;
          border: 1px solid #8c95c8;

          input {
            border-radius: 4px 0 0 0;
            font-size: inherit;
          }
        }
      }
    }
  }
}
