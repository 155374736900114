.keyboard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    @media (min-height: 630px) {
        width: 3.5rem;
        height: 3.5rem;
    }
    border-radius: 50%;
    cursor: pointer;
    font-size: 1rem;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    :nth-child(n) {
        font-size: 1.5rem;
    }
    @media (min-width: 720px) {
        width: 4rem;
        height: 4rem;
        :nth-child(n) {
            font-size: 2rem;
        }
    }
}
