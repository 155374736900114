@import "../../theme/common";

.departmentsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  color: white;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;

  .departmentsInnerContainer {
    flex: 1;
    width: 100%;
  }

  .departmentTitle {
    font-family: Roboto;
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
  }

  .departmentItems {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  .departmentItem {
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 2px 2px 3px 0 #f2f2f2;
    border: 1px solid #f2f2f2;
    // margin: 0 15px 10px 15px;
    padding: 10px;
    color: #4e4f6b;
    width: 90%;
    min-width: 0;
    cursor: pointer;
  
    .chat-new-message-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -6px;
      left: -6px;
  
      border-radius: 50%;
      background-color: #5465e6;
      width: 18px;
      height: 18px;
  
      svg {
        width: 8px;
        height: 10px;        
      }
    }
   

    .departmentName {
      display: flex;
      align-items: center;
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;

      .indicator {
        width: 8px;
        height: 8px;
        background-color: #d0021b;
        border-radius: 50%;
        margin-right: 8px;
      }

      .indicator.active {
        background-color: #6db41f;
      }

      .name {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .departmentDescription {
      height: 80%;
      font-size: 14px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f6f7;
      }
    }

    .chevron {
      position: absolute;
      top: 9px;
      right: 1px;
      width: 24px;
      height: 24px;
      transform: rotate(180deg);

    }
  }

  .departmentItem.blocked {
    color: #bbc1ca;
  }

  .departmentItem:last-child {
    margin-bottom: 130px;
  }

  .departmentsGoBack {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .departmentsContainer {
    .departmentTitle {
      font-size: 2rem;
      font-weight: normal;
    }

    .departmentTitle, .departmentsInnerContainer {
      width: 800px;
    }

    .departmentItem {
      margin: 0 5px 10px 5px;
      width: calc(50% - 10px);
      height: 120px;
      box-sizing: border-box;
    }
  }
}
