.modal {
  @media (max-width: 500px) {
    .modal-head {
      h3 {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 375px) {
    .modal-head {
      h3 {
        font-size: 0.9rem;
      }
    }
  }

  .modal-content {
    &.consultation-modal-content {
      max-width: 90% !important;
      width: 27rem !important;
    }

    .modal-body {
      padding: 0;
    }
  }
}

.mediaDevicesContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.disabledButton {
  opacity: .4;
  pointer-events: none;
}

.devicesCheckVideoContainer {
  display: flex;
  justify-content: center;
  height: 20rem;
  max-height: 50%;
  margin: 1rem 0;
}

.devicesCheckVideo {
  max-width: 100%;
  border-radius: 4px;
}

.noMediaDevicesAccessMessage {
  margin-bottom: 1rem;
}
