.modal {
  .modal-content {
    &.consultation-modal-content {
      max-width: 90% !important;
      width: 25rem !important;
    }

    .modal-head {
      width: 100%;
    }

    .modal-body {
      padding: 0;
      width: 100%;
    }
  }
}

.terms-please-read-sign {
  margin: 0;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-size: 1rem;
  color: #b7b9c3;
}

.termsContainer {
  background-color: #f5f7f8;
  border-radius: 4px;
  border: solid 1px #f4f4f4;
  height: 300px;
  margin: 0 1rem 1rem;
  font-size: 12px;
}

.disabledButton {
  opacity: 0.4;
  pointer-events: none;
}
