.chat-body-messages-container {
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 2;
  flex-basis: 90%;

  &.chat-ai {
    background-color: #000000;
    background-image: url('../../assets/chat-ai-waves-background.svg');
    background-repeat: no-repeat;
    background-position: bottom;

    .chat-ai-background {
      position: absolute;
      width: 1065px;
      height: 1065px;
      left: -149px;
      top: 225px;

      background: #0021D6;
      opacity: 0.5;
      filter: blur(250px);
      
    }
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
  }

  .chat-body-operator-write-message-container {
    display: flex;
    margin-left: 12px;
    max-width: 100%;

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #9ba1af;
      word-break: break-word;

      span{
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      }
  
      span:nth-child(2) {
        animation-delay: .2s;
      }
  
      span:nth-child(3) {
        animation-delay: .4s;
      }      
      
    }

  }

  .chat-body-icon-with-message {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-height: 500px) {
      display: none;
    }

    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9ba1af;
    }

  }

  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

  .chat-body-icon-with-waiting-message {
    animation-name: topappearance;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    position: relative;
    flex-flow: row nowrap;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    p {
      margin-left: 10px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9ba1af;
    }

    p span{
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }

    p span:nth-child(2) {
      animation-delay: .2s;
    }

    p span:nth-child(3) {
      animation-delay: .4s;
    }

  @keyframes topappearance {

    0% {
      top: -100px;
    }

    100% {
      top: 1%;
    }
    
  }

  }
}