.operator-offline-modal-content {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-around;

    h3 {
        max-width: 100%;
        font-size: 1.2rem !important;
        color: black !important;
    }

    .operator-offline-modal-button {
        color: white;
        border-radius: 4px;
        width: 6.5rem;
        cursor: pointer;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .operator-offline-modal-button-primary {
        @extend .operator-offline-modal-button;
        background-color: #5465e6;
    }

    .operator-offline-modal-button-secondary {
        @extend .operator-offline-modal-button;
        background-color: #b7b9c4;
    }

}

.modal {
    .modal-content {
        width: 90%;
        max-width: 35rem;
        .modal-wrapper {
            padding: 0.5rem !important;
        }
    }
    .modal-head {
        max-width: 100%;
        h3 {
            word-break: break-word;
            white-space: pre-wrap;
        }
    }
}
