.keyboard-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  align-items: center;
  justify-items: center;
  width: 100%;

  @media (min-height: 720px) {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .delete-button {
    width: 3rem;
    height: 3rem;

    @media (min-height: 630px) {
      width: 3.5rem;
      height: 3.5rem;
    }

    cursor: pointer;

    @media (min-width: 720px) {
      width: 4rem;
      height: 4rem;
    }
  }

  .call-button {
    &:active {
      opacity: 0.5;
    }

    .call-icon {
      width: 1.5rem;
      height: 1.5rem;

      @media (min-width: 720px) {
        width: 2rem;
        height: 2rem;
        max-width: 75%;
        max-height: 75%;
      }
    }
  }
}
