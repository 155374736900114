.leave-message-button-wrapper {
  height: 39px;
  width: 160px;

  & .leave-message-button {
    width: 100%;
    height: 100%;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
