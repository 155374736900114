.screen-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .call-buttons-wrapper {
    margin-top: 1rem;
    max-width: 15rem;

    @media (min-width: 720px) {
      margin-top: 2rem;
      max-width: 20rem;
    }

    @media (min-height: 720px) {
      padding: 0 10%;
    }

    .chat-ai-button {
      width: 3rem;
      height: 3rem;

      @media (min-height: 630px) {
        width: 3.5rem;
        height: 3.5rem;
      }

      margin: 0 0.25rem;

      @media (min-width: 720px) {
        width: 4rem;
        height: 4rem;
        margin: 0 0.5rem;
      }
    }

    .audio-consultation-button {
      width: 3rem;
      height: 3rem;

      @media (min-height: 630px) {
        width: 3.5rem;
        height: 3.5rem;
      }

      margin: 0 0.25rem;

      @media (min-width: 720px) {
        width: 4rem;
        height: 4rem;
        margin: 0 0.5rem;
      }
    }

    .video-consultation-button {
      width: 3rem;
      height: 3rem;

      @media (min-height: 630px) {
        width: 3.5rem;
        height: 3.5rem;
      }

      margin: 0.25rem;

      @media (min-width: 720px) {
        width: 4rem;
        height: 4rem;
        margin: 0.5rem;
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 10%;
    max-width: 15rem;

    @media (min-height: 720px) {
      padding: 0 10%;
    }

    @media (min-width: 720px) {
      max-width: 20rem;
    }

    .header {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin-top: 0.5rem;

      @media (min-height: 720px) {
        margin-top: 1.5rem;
      }

      .screen-logo {
        max-width: 2rem;
        max-height: 2rem;
        border-radius: 0.2rem;
      }

      .title {
        font-size: 0.7rem;

        @media (min-width: 375px) {
          font-size: 0.8rem;
        }

        @media (min-width: 450px) {
          font-size: 1rem;
        }

        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        @media (min-height: 720px) {
          margin-top: 1.5rem;
        }

        padding: 0;
        font-style: normal;
        font-weight: normal;
        line-height: 1rem;
        text-align: center;
        word-wrap: break-word;
        max-width: 100%;
      }
    }
  }

  .tile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    > :nth-child(n) {
      margin-top: 0.5rem;

      @media (min-height: 720px) {
        margin-top: 1.5rem;
      }
    }
  }

  .qr-intercom-icon {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}

.modal-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;

  .modal-error-header {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .modal-error-text {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
  }

  .modal-error-button {
    margin-top: 2rem;
    color: #536AE2;
    outline: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}
