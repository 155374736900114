.spinnerContainer {
  position: relative;
  width: 7rem;
  height: 7rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
}

.spinnerContainer svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
}

.spinner {
  text-indent: -9999em;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #5465e6;
  background: linear-gradient(to right, #5465e6 10%, #2f3243 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
  margin-bottom: 1rem;
}

.spinner::before {
  width: 50%;
  height: 50%;
  background: #5465e6;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.spinner::after {
  background: #000000;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
