@import "../../theme/common";

.leavePhoneNumberContainer {
  background-color: #000000;
  color: white;
  display: flex;
  flex-direction: column;

  .leavePhoneForm {
    padding: 0 3rem;
    text-align: center;

    textarea {
      min-height: 65px;
    }
  }

  .externalForm {
    display: flex;
    align-items: center;
    flex-direction: column;

    a {
      padding-bottom: 3rem;
      color: white;
    }

    a:hover, a:active, a:visited {
      color: white;
    }
  }

  & > a {
    color: #FFFFFF;
    margin-bottom: 3rem;
  }

  .leaveNumberTitle {
    @extend %elipsis;

    text-align: center;
    margin-bottom: 2.5rem;
  }

  .webscreenInput {
    margin-bottom: 1.5rem;
  }

  .webscreenTextAreaContainer {
    margin-bottom: 2.5rem;
  }

  .formError {
    font-size: 1rem;
    color: red;
    margin-bottom: 0.1rem;
  }

  .qrCodeButton {
    margin-bottom: 0.5rem;
  }

  .phoneNumberField {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.5rem;

    .webscreenInput {
      margin-bottom: 0;
    }

    .dropdownContainer {
      z-index: 10;
      font-size: 0.8rem;
      width: 6rem;
      padding: 14px 5px;
      margin-right: 1rem;
    }

    .phoneFieldPlaceholder {
      position: absolute;
      bottom: 100%;
      font-size: 0.7rem;
    }
  }
}

.terms-link {
  color: #5465e6;
}

.successfully-sended-pop-up-wrapper {
  display: flex;
  flex-flow: column nowrap;
  background-color: #000000;
  justify-content: space-between;
  align-items: center;

  .success-icon-wrapper {
    border-radius: 50%;
    background-color: #3d3d52;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    margin-bottom: 1.5rem;

    img {
      width: 30%;
      height: 30%;
    }
  }

  h1 {
    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
    padding: 0 1rem;
    max-width: 15rem;
    font-size: 1rem;
    margin-bottom: 8rem;
  }
}
