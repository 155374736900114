.mw-no-screens {
  text-align: center;
  margin-top: 1rem;
  color: white;
}

.mw-swipe-container {
  height: 100vh;
  overflow: hidden;
  background-color: #000000;

  .react-swipeable-view-container {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
