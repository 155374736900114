.bell-button {
  margin: 0 0.25rem;

  @media (min-width: 720px) {
    margin: 0 0.5rem;
  }

  &:active {
    opacity: 0.5;
  }

  .bell-icon {
    width: 1.5rem;
    height: 1.5rem;

    @media (min-width: 720px) {
      width: 2rem;
      height: 2rem;
      max-width: 75%;
      max-height: 75%;
    }
  }
}
