.display-number-layout {
    width: 92%;
    min-height: 3rem;
    border-radius: .3rem;
    margin-top: 0;
    margin-bottom: 0.5rem;

    .display-number-value {
        padding: 0.5rem 0;
        margin: 0 auto;
        font-size: 1.5rem;
        color: #ffffff;
        width: 100%;
        text-align: center;
    }
}
