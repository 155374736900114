.snapshotWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 30;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &.visible {
    visibility: visible;
  }
  &.with-chat-right {
    width: calc(100vw - 25vw);
  }

  .board-wrapper {
    width: 100%;
    position: relative;

    canvas {
      object-fit: contain;
      width: 100%;
      max-height: 80vh;
      border-radius: 3px;
    }
    .drawingCanvas {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .snapshotContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    width: 90%;
    .close-button {
      align-self: flex-end;
      margin-bottom: 0.5rem;
      background: none;
      border: none;
      outline: none;
      padding: initial;
      cursor: pointer;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    .zoom-slider-container {
      z-index: 1000;
      left: 50%;
      top: 7%;
      transform: translate(-50%, 0%);
      position: absolute;
    }
  }
}
