.dropdownContainer {
  position: relative;
  overflow: visible;

  display: inline-block;

  padding: 12px 5px;
  background-color: transparent;
  width: 100%;

  border-bottom: solid 2px #8c95c8;

  color: white;
  border-radius: 0;

  .dropdownContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    max-height: 20rem;

    background-color: white;
    color: black;

    .dropdownItem {
      padding: 12px 5px;

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .dropdownItem:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    input {
      width: 100%;
      font-size: 1rem;
      border: none;
      padding: 12px 0;
      text-align: center;
    }
  }
}
