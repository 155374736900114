@import "../../theme/common";

.consultationRequestContainer {
  text-align: center;
  color: white;
  background-color: #000000;
  align-items: center !important;

  .positionInQueue {
    transform: translateY(-30px);

    .calling-icon {
      margin-bottom: 1rem;
    }

    .positionText {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.3;
    }

    .numberText {
      font-size: 52px;
      margin-bottom: 10px;
    }

    .spinnerContainer {
      margin: 0 auto 5rem;
    }
  }

  &.with-leave-a-phone-number-button {
    align-items: flex-start !important;

    .positionInQueue {
      margin-top: 100px;
    }
  }

  .questionContainer {
    transform: translateY(-50px);

    .confirmationQuestion {
      margin-bottom: 20px;
    }

    .qrCodeButton {
      margin-bottom: 15px;
    }

    .requestTickerTime {
      opacity: 0.4;
      margin-left: 10px;
    }
  }

  .leave-a-message-button {
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .callStopIcon {
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

.connectingContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  color: white;
}

.requestCompanyName {
  @extend %elipsis;

  font-size: 18px;
  margin-bottom: 20px;
}



@media (min-height: 650px) {
  .consultationRequestContainer {
    &.with-leave-a-phone-number-button {
      align-items: center !important;
      .positionInQueue {
        margin-top: unset;
      }
    }
  }
}

@media (min-height: 800px) {
  .consultationRequestContainer {
    .qrCodeButton {
      padding: 14px 70px;
      bottom: 170px !important;
    }
    
    .positionInQueue {
      .requestCompanyName {
        margin-bottom: 50px;
      }

      .positionText {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 1.5;
      }

      .numberText {
        font-size: 64px;
        margin-bottom: 50px;
      }
    }

    .callStopIcon {
      bottom: 50px;
    }
  }
}
