.opened-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.closed-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.mw-call-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;

  &.collapsedButtons {
    justify-content: flex-end;
  }

  &.bottomButtons {
    justify-content: space-around;
  }

  .chat-new-message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -9%;
    right: -9%;
    border-radius: 50%;
    background-color: #5465e6;
    width: 18px;
    height: 18px;

    svg {
      width: 8px;
      height: 10px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    position: relative;
    margin: 0 0.3rem;
    cursor: pointer;
    border-radius: 50%;

    &.disabled {
      pointer-events: none;

      &.withClick {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    p {
      min-width: 5.5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1.6rem;
      font-family: Roboto;
      font-size: 0.65rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #5465e6;
    }
  }

  .buttonContainer .call-text {
    display: none;
  }

  .buttonContainer.small {
    height: 2.5rem;
    width: 2.5rem;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  .icon-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    pointer-events: none;
  }
}

@media only screen and (min-width: 1024px) {
  .mw-call-buttons {
    .buttonContainer {
      height: 3.2rem;
      width: 3.2rem;
      margin: 0 10px;
    }

    .buttonContainer.type-audio, .buttonContainer.type-video {
      border-radius: 25px;
      color: white;
      font-size: 16px;

      .call-text {
        display: block;
      }
    }

    .icon-shadow {
      display: none;
    }
  }
}
