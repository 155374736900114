.mw-file-screen {
  display: flex;
  flex-flow: column;
  .mw-screen-file-container {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    background-color: white;
    iframe {
      border-width: 0;
      background-color: white;
    }
    .documentPreview.desktop {
      width: 200%;
      height: 200%;
    }
  }
}

.pdf-reload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;

  background: #bbc0cb;
  transition: height 0.2s;
  box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);

  .pdf-reload-message {
    color: #4e4f6b;
    font-size: 0.875rem;

    .reload-button {
      margin-left: 0.5rem;

      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .close-button {
    width: 1.5rem;
    height: 1.5rem;

    cursor: pointer;
  }
}
