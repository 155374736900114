.mw-screen-container {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);;

  background-color: white;
  overflow: hidden;
  z-index: 10;

  .mw-logo {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    width: 8rem;
    -webkit-transform: translate3d(0,0,0);
    z-index: 10000;
    img {
      width: 100%;
    }
  }

  .mw-uploaded-logo {
    cursor: pointer;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 4.5rem;
    width: 6rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate3d(0,0,0);
    z-index: 10000;

    &.withExpandedCallButtons {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
