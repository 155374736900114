.mw-text-screen {
  background-color: white;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-content {
    display: flex;
    margin: 6rem auto 0;
    justify-content: center;
    align-items: center;
    max-width: 80vw;

    .scrollbars{

      .text-overflow {

        .screen-text {
          text-align: center;
          font-weight: 500;
          font-size: 1.2rem;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          word-break: break-word;
          white-space: pre-wrap;
        }

      }

    }
    ::-webkit-scrollbar {
      background: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

  }

  .mn-call-buttons-container {
    position: absolute;
    bottom: 4rem;
    z-index: 1000;
    width: 15rem;
    max-width: 80%;

    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }

    &.top-right {
      bottom: auto;
      top: 0;
      right: 0;
      height: 5rem;
      width: auto;
      padding: 0 0.5rem;
      box-sizing: border-box;

      @media only screen and (max-width: 768px) {
        &.mw-call-buttons-opened {
          z-index: 9000;
          width: 100%;
          background-color: #ffffff;
        }
      }

      .mw-call-buttons {
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .mw-text-screen {
    display: flex;
    align-items: center;
    justify-content: center;

    .text-content {
      display: flex;
      flex-direction: column;
      max-width: 80vw;

      .scrollbars {
        .text-overflow {
          position: relative;
          margin-top: 0;

          .screen-text {
            margin-top: 0;
            margin-bottom: 6rem;
            font-size: 2rem;
            font-weight: normal;
            word-break: break-word;
            white-space: pre-wrap;
          }
        }

      }

      .mn-call-buttons-container:not(.top-right) {
        bottom: 5rem;
      }

    ::-webkit-scrollbar {
      background: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    }
  }
}
