@import "../../theme/common";

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 18px;
}

body {
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;

  *:not(input):not(textarea) {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.appContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.contentContainer {
  @extend %absolute-fullsize;

  @extend %flex-center;
}
