@import "../../theme/common";

.consultationContainer {
  @extend %absolute-fullsize;

  @extend %flex-center;

  background-color: #000000;

  &.with-chat-right {
    width: 75vw;

    .pauseOverlay {
      width: 75vw;
    }
  }

  .chat-container {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;

    &.chat-right {
      position: fixed;
      width: 25vw;
      right: 0;
    }
  }

  .pauseOverlay {
    @extend %absolute-fullsize;

    @extend %flex-center;

    color: white;
    flex-flow: column;
    background-color: #000000;
    z-index: 10;

    .loader {
      margin-bottom: 1rem;
    }

    .hangOutIconPause {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #e96878;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 50px;

      svg {
        height: 40px;
      }
    }
  }

  .audioOnlyPlaceholder {
    @extend %absolute-fullsize;

    @extend %flex-center;

    color: white;
  }

  .consultantInfo {
    position: absolute;
    top: 10px;
    left: 15px;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    z-index: 20;
    max-width: 90%;

    div:first-child {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 25px;
    }

    div:last-child {
      font-size: 18px;
    }
  }

  .bigVideoBlock {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: #000000;
  }

  .bigVideoBlock.hidden {
    display: none;
  }

  .smallVideoBlock {
    position: absolute;
    z-index: 30;
    top: 16px;
    right: 16px;
    height: 180px;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(47, 50, 67, 0.63);
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .smallVideoBlock.faded {
    opacity: 0;
  }

  .smallVideoBlock.hidden {
    opacity: 0;
  }

  @media (max-width: 800px) and (orientation: landscape) {
    .smallVideoBlock {
      height: 100px;
    }
  }

  .gradientTop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30%;
    opacity: 0.3;
    background-image: linear-gradient(to top, rgba(47, 50, 67, 0), #2f3243);
  }

  .gradientBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    opacity: 0.3;
    background-image: linear-gradient(to bottom, rgba(47, 50, 67, 0), #2f3243);
  }

  .streamControls {
    position: absolute;
    bottom: 50px;
    z-index: 30;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .description-wrapper {
      border-radius: 3px;
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 0 0.3rem;
      position: absolute;
      bottom: calc(100% + 0.5rem);
      left: 50%;
      transform: translateX(-50%);
      width: 15.5rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-evenly;

      p {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }

    > :nth-child(n + 2) {
      margin-top: 20px;
    }

    .control-line {
      display: flex;
      align-items: center;

      > :nth-child(n) {
        margin: 0 10px;
      }

      .reload-audio-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: #ffffff;

        &.loading {
          background-color: #6d6f7b;
        }

        svg {
          width: 100%;
          height: 100%;
        }

        @media (max-height: 700px) {
          width: 36px;
          height: 36px;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }
      }

      & .hang-out-icon, & .hang-out-button-animated {
        z-index: inherit;
        width: 48px;
        height: 48px;

        @media (max-height: 700px) {
          width: 36px;
          height: 36px;
        }
      }
    }

    .control-background {
      position: relative;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      outline: none;
      border: none;
    }

    svg {
      height: 65%;
      min-height: 1rem;
      max-height: 2.2rem;
    }

    .hangOutIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #e96878;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }

    @media (max-height: 700px) {
      bottom: 40px;

      > :nth-child(n + 2) {
        margin-top: 10px;
      }

      .control-background {
        width: 36px;
        height: 36px;
      }

      .hangOutIcon {
        width: 36px;
        height: 36px;
      }
    }

    .chat-new-message-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -10%;
      right: -5%;
      border-radius: 50%;
      background-color: #5465e6;
      width: 18px;
      height: 18px;

      svg {
        width: 8px;
        height: 10px;
      }
    }

    .disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }

  .audioCallSection {
    display: flex;
    align-items: center;
    flex-flow: column;
    max-width: 80%;
    overflow: hidden;

    .avatarSection {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background-color: #5465e6;
      overflow: hidden;
      margin-bottom: 1rem;

      svg {
        width: 60px;
        height: 60px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .titleForCons, .consultantName {
      color: #ffffff;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .consultantName {
      font-size: 25px;
    }

    .titleForCons {
      font-size: 18px;
      opacity: 0.5;
    }
  }

  @media (orientation: landscape) {
    .audioCallSection {
      transform: translateY(-3rem);
    }

    .streamControls {
      bottom: 20px;
    }
  }
}

.recording-notifcation {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 1000;
}
