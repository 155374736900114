.zoom-slider-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width:12rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: rgba($color: #000000, $alpha: 0.5);

    input[type=range]{
        -webkit-appearance: none;
        cursor: pointer;
    }
    
    input[type=range]::-webkit-slider-runnable-track {
        width: 15rem;
        height: 0.22rem;
        background: #ffffff;
        border: none;
        border-radius: 1.5px;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0 0 0.150rem #000000;
        margin-top: -0.4rem;
    }
    
    input[type=range]:focus {
        outline: none;
    }
    
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #ccc;
    }
}