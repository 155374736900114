.deviceSelect {
  position: relative;
  width: 48%;
}

.deviceButton {
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.5rem;
  box-sizing: border-box;

  &.center {
    justify-content: center;
  }

  svg {
    height: 2rem;
    width: 2rem;
  }

  .name {
    flex-grow: 1;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.devicesList {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  max-height: 180px;
  background-color: #e5e7e8;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  box-sizing: border-box;
  z-index: 10;

  .deviceButton {
    margin-top: 2px;
    justify-content: space-between;
    font-size: 18px;
    letter-spacing: 0.28px;
    color: #6d7184;
    white-space: nowrap;

    &:hover {
      color: #638e8a;
      background-color: #f5f7f8;
    }

    &:first-child{
      margin-top: 0;
    }
  }
}

.arrow {
  width: 0;
  border: 6px solid transparent;
  border-bottom: 0 solid transparent;
  border-top: 6px solid #ffffff;
  transform: rotateZ(0deg);
  transition: transform .3s;

  &.up {
    transform: rotateZ(180deg);
  }
}

.devicesList {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #f5f6f7;
  }
}
