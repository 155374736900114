.chat-header-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding-right: 12px;
    padding-left: 12px;
    min-height: 72px;
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    z-index: 1;
    flex-shrink: 0;
    flex-basis: 7%;

    .chat-header-button-back {
        position: absolute;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat-header-operator-info {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: auto;
        max-width: calc(75% - 100px);

        &.without-buttons {
            max-width: 75%;
        }

        img {
            width: 36px;
            height: 36px;
            min-height: 36px;
            min-width: 36px;
            border-radius: 50%;
            margin-right: 10px;
        }

        h1 {
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }

    h1 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4e4f6b;
        margin: auto;
    }

    .chat-header-buttons-wrapper {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .chat-header-consultation-button {
            margin-right: 10px;
            cursor: pointer;
        }

        & .disabled {
            cursor: initial;
            pointer-events: none;
        }

    }

}