.qrCodeButton {
  padding: 14px 70px;
  color: white;
  border-radius: 5px;
  text-align: center;
  &.button-primary {
    background-color: #5465e6;
  }

  &.button-default {
    background-color: #3d3d52;
  }

  &.button-disabled {
    pointer-events: none;
    background-color: #9b9b9b;
  }
}
