@import "../../theme/common.scss";

.loading-container {
  position: relative;
  width: 6rem;
  height: 6rem;
  margin-bottom: 1.45rem;
}

.loadingOverlay {
  @extend %fixed-fullsize;

  @extend %flex-center;

  color: white;
  flex-flow: column;
  background-color: #000000;
  z-index: 10;

  &.withChat {
    @extend %fixed-reduced;

    width: 75vw;
  }

  & .loading-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    & .loading-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-connect-to {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
      }

      &-wait {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #828C9E;
      }
    }
  }
}

.controls-wrapper {
  position: absolute;
  bottom: 96px;

  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;

    & .hang-out-icon, & .hang-out-button-animated {
      z-index: inherit;
      width: 60px;
      height: 60px;
    }
  }
}
