.recording-notification-wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(143.77px);
    border-radius: 2.1rem;
    max-height: 37.5rem;
    max-width: 13rem;

    .info-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .recording-notification-info {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: .5rem;

        .recording-header {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: .7rem;
            line-height: 1.1rem;
            color: #FFFFFF;
            margin: unset;
            padding: unset;
        }

        .recording-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: .7rem;
            line-height: 1.1rem;
            color: #FFFFFF;
            margin: unset;
            padding: unset;
        }
    }

    @media (min-width: 400px) and (min-height: 500px) {
        max-width: 18rem;

        .info-icon {
            width: 1.7rem;
            height: 1.7rem;
        }

        .recording-notification-info {
            gap: .7rem;

            .recording-header {
                font-size: .9rem;
            }

            .recording-text {
                font-size: .9rem;
            }
        }
    }
}
