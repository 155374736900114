%absolute-fullsize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

%fixed-fullsize {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

%fixed-reduced {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%elipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customYScroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f6f7;
  }
}
