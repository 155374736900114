.mw-screen-header {
  flex-shrink: 0;

  position: relative;
  width: 100%;
  height: 5rem;
  box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  z-index: 10;

  @media only screen and (max-width: 768px) {
    &.mw-call-buttons-opened {
      z-index: 9000;
      .mn-call-buttons-container { 
        width: 100%;
        background-color: white;
      }
    }
  }


  .mw-screen-title {
    margin: 0 6rem 1.5rem;
    color: #4e4f6b;
    //padding: 0 6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 11;
  }

  .mn-call-buttons-container {
    .buttonContainer.small {
      height: 2.5rem;
      width: 2.5rem;
    }
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 0.5rem;
    box-sizing: border-box;
    .mw-call-buttons {
      height: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .mn-call-buttons-container {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1000;
      .mw-call-buttons {
        height: 100%;
      }
    }
  }
}
