.enter-lock-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.enter-lock-screen-content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 60vh;
  padding-bottom: 3rem;
  max-height: 15rem;
  max-width: 12.5rem;
}

.enter-lock-text {
  width: 270px;
  height: 21px;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.code-row {
  position: relative;
  width: 100%;

  input, .cells-wrapper {
    width: 12.5rem;
    height: 2.5rem;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .cells-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cell {
    width: 16%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
}

.enter-lock-screen-submit-button {
  width: 198px;
  height: 40px;
  border-radius: 3px;
  background-color: #5465e6;
  outline: none;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
  }
}
